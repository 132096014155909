
<template>
  <div>
    <PageHeader />
    <b-overlay :show="game.isFetchingGame">
      <b-card>
        <b-row
          v-if="gameDetail && gameDetail.id"
          class="mb-3"
        >
          <b-col cols="2">
            <p class="m-0">
              {{ `#${gameDetail.id}`}}
            </p>
          </b-col>
          <b-col class="text-muted">
            <p class="mb-2">
              <i class="uil-calender"></i>
              {{ $t('fields.createdAt')}}
            </p>
            <h5 class="text-muted">
              {{gameDetail.createdAt | datetime}}
            </h5>
          </b-col>
          <b-col class="text-muted">
            <p class="mb-2">
              <i class="uil-calender"></i>
              {{ $t('fields.updatedAt')}}
            </p>
            <h5 class="text-muted">
              {{gameDetail.updatedAt | datetime}}
            </h5>
          </b-col>
          <b-col class="text-right">
            <p class="mb-1">
              {{ gameDetail.isActive? $t('utils.status.active'): $t('utils.status.inactive')}}
            </p>
            <b-form-checkbox
              v-model="isActive"
              switch
              size="lg"
              @change="onUpdateStatus"
            >
            </b-form-checkbox>
          </b-col>
        </b-row>
        <ValidationObserver
          ref="gameForm"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              v-slot="{ errors }"
              :name="$t('games.name')"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                :label="`${$t('games.name')} *`"
                label-for="gameName"
              >
                <b-form-input
                  id="gameName"
                  v-model="gameData.gameName"
                  :state="errors[0] ? false : null"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="Slug"
              rules="required"
            >
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="2"
                label="Slug *"
                label-for="slug"
              >
                <b-input-group>
                  <b-form-input
                    id="slug"
                    v-model="gameData.slug"
                    :state="errors[0] ? false : null"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      :disabled="!gameDetail.id"
                      :to="`${clientUrl}/games/${gameData.slug}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="uil uil-eye"></i>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>

              </b-form-group>
            </ValidationProvider>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="$t('games.transaction_fee')"
              label-for="waterFee"
            >
              <b-row
                v-for="(feeForm, index) in gameData.gameWaterTransactions"
                :key="index"
                class="mb-3"
              >
                <b-col cols="3">
                  <b-input-group prepend="Min (฿)">
                    <b-input
                      :id="`minBet${index}`"
                      v-model="feeForm.minBet"
                      type="number"
                    ></b-input>
                  </b-input-group>
                </b-col>
                <b-col
                  cols="3"
                  class="pl-0"
                >
                  <b-input-group prepend="Max (฿)">
                    <b-input
                      :id="`maxBet${index}`"
                      v-model="feeForm.maxBet"
                      type="number"
                    ></b-input>
                  </b-input-group>
                </b-col>
                <b-col class="pl-0">
                  <b-input-group :append="feeForm.waterFeeType === 'AMOUNT'?'฿': '%'">
                    <template #prepend>
                      <b-form-select
                        v-model="feeForm.waterFeeType"
                        :options="waterFeeTypes"
                      >
                      </b-form-select>
                    </template>
                    <b-input
                      :id="`waterFee${index}`"
                      v-model="feeForm.waterFee"
                      type="number"
                    >
                    </b-input>
                  </b-input-group>
                </b-col>
                <b-col
                  v-if="index > 0"
                  cols="1"
                  class="p-0"
                >
                  <b-button
                    variant="danger"
                    @click="onRemoveWaterFee(index)"
                  >
                    <i class="uil uil-trash"></i>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-text>
                    {{ `หมายเหตุ: ค่าน้ำจะคำนวนจากยอดแทงของผู้เล่นในห้อง`}}
                  </b-form-text>
                  <b-button
                    variant="link"
                    size="sm"
                    @click="onAddWaterFee"
                  >
                    {{ $t('buttons.add')}}
                  </b-button>

                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="$t('fields.description')"
            >
              <vue-editor v-model="gameData.rule"></vue-editor>
            </b-form-group>

            <b-form-group
              label-cols-sm="12"
              label-cols-lg="2"
              :label="$t('fields.allow_concurrent')"
            >
              <b-form-checkbox
                v-model="gameData.isAllowConcurrent"
                switch
                size="lg"
              >
              </b-form-checkbox>
            </b-form-group>

            <div class="text-right">
              <b-button
                class="mr-2"
                @click="$router.go(-1)"
              >
                {{ $t('buttons.back')}}
              </b-button>
              <b-overlay
                :show="game.isUpdatingGame || game.isCreatingGame"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.submit') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Game detail',
  },
  components: { VueEditor },
  data() {
    return {
      isActive: false,
      waterFeeTypes: [
        { text: this.$t('fields.amount'), value: 'AMOUNT' },
        { text: this.$t('fields.percentage'), value: 'PERCENT' },
      ],
      gameData: {
        gameName: '',
        gameDescription: '',
        gameType: 'GAME_DEMO',
        gameKind: 'MINI_GAME',
        maximumPlayer: 2,
        minimumPlayer: 2,
        maximumBet: 20,
        minimumBet: 3200,
        rule: '',
        slug: '',
        waterFeeType: 'PERCENT',
        waterFee: 5,
        isAllowConcurrent: false,
        gameWaterTransactions: [
          { maxBet: '', minBet: '', waterFee: '', waterFeeType: '' },
        ],
      },
    }
  },
  computed: {
    ...mapState(['game']),
    ...mapGetters(['gameDetail']),
    gameId() {
      return this.$route.params.id
    },
    clientUrl() {
      return `${process.env.VUE_APP_CLIENT_URL}`
    },
  },
  watch: {
    gameDetail(val) {
      if (val) {
        this.setData()
      }
    },
  },
  async created() {
    if (this.gameId) {
      await this.fetchGame(this.gameId)
      this.setData()
    }
  },
  methods: {
    ...mapActions([
      'fetchGame',
      'createGame',
      'updateGame',
      'updateGameStatus',
    ]),
    setData() {
      if (this.gameDetail && this.gameDetail.id) {
        const data = JSON.parse(JSON.stringify(this.gameDetail))
        this.isActive = !!data.isActive
        this.gameData = {
          ...data,
        }
      }
    },
    onUpdateStatus() {
      this.updateGameStatus(this.gameId)
    },
    onRemoveWaterFee(index) {
      if (this.gameData.gameWaterTransactions.length > 1) {
        this.gameData.gameWaterTransactions.splice(index, 1)
      }
    },
    onAddWaterFee() {
      if (this.gameData.gameWaterTransactions.length > 0) {
        const prevMinBet = this.gameData.gameWaterTransactions[
          this.gameData.gameWaterTransactions.length - 1
        ].maxBet
        this.gameData.gameWaterTransactions.push({
          minBet: +prevMinBet + 1,
          maxBet: +prevMinBet + 1001,
          waterFeeType: 'PERCENT',
          waterFee: 1,
        })
      }
    },
    onSubmit() {
      if (this.gameData.id) {
        this.updateGame(this.gameData)
      } else {
        this.createGame(this.gameData)
      }
    },
  },
}
</script>
